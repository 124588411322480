
  .alice-carousel__next-btn
  .alice-carousel__next-btn-item

  {
    position: absolute;
    top: 20%;
    transform: translateY(-50%);
    background-color: transparent;
    color: #809ad4;
  }
  .alice-carousel__next-btn:hover
  .alice-carousel__next-btn-item:hover
  {
    color: white;
  }
  .alice-carousel__prev-btn
  .alice-carousel__prev-btn-item
  {
    position: absolute;
    top: 20%;
    transform: translateY(-70%);
    background-color: transparent;
    color: #809ad4;
   

  }
  .alice-carousel__prev-btn:hover
  .alice-carousel__prev-btn-item:hover
  {

    color:white;
  }

  .alice-carousel__prev-btn
  .alice-carousel__prev-btn-item {
     right: 100%;
    transform: translateY(-50%);
  }
  
 

  .alice-carousel__next-btn-item {
    left: 100%;
    transform: translateY(-50%);
  }


@media (min-width: 1800px){
  .cont-home {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;


  }
  
  .input-placeholder{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
 
  }
  
    .hom-placeholders {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center; 
    
      }
      .input-button-container {
        display: flex;
        position: relative;
        width: 700px;
   
      }
    
    
      .random-button{
        position: absolute;
        display: flex;
        background-color: transparent;
        color: #809ad4;
        border: none;
        padding: 8px;
        right: 0;
        margin-top: 10px;
        border-radius: 30px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
      }
      
      .random-button:hover {
        background-color: transparent; 
        color: #5d74a5;
      }        

  .result_image_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 420px;
    height: 280px;
    background-color: transparent;
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;
 
  }
  .result_imaage_container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 420px;
    height: 280px;
    background-color: transparent;
    border-radius: 10px;
    padding: 20px;
    margin: 0 auto;

  }

  .result_img{
    opacity: 0.5;
    border-radius: 10px;
    width: 420px;
    height: 280px;

  }
  .resultt_img{
    border-radius: 10px;
    width: 420px;
    height: 280px;

  }

  .cancel-button {
    font-size: 16px;
font-weight: 600;
cursor: pointer;
color: white;
height: 30px;
width: 30px;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(171, 171, 171, 0.3);
border: none;
border-radius: 50%;
backdrop-filter: blur(7px);
-webkit-backdrop-filter: blur(7px);
position: absolute; 
top: 10px; 
right: 10px;
z-index: 9999;
  }
  
  .icons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .iconss {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 91%;
    left: 0;
    right: 0;

  }
  .icons .download-image
{
    width: 25px;
    height: 25px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-left-radius: 10px;
  }
  .icons .edit-icon-image {
    width: 25px;
    height: 25px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-right-radius: 10px;
   
  }
  .iconss .download-img{
    width: 25px;
    height: 25px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-left-radius: 10px;
  }
  .iconss .edit-icon-img {
    width: 25px;
    height: 25px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-right-radius: 10px;
  }

  .alice-carousel__dots {
    position: absolute;
    bottom: 0; 
    left: 0;
    right: 0;
  }
  .slider-values-range {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto; 
  }
  .slider {
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(to left, #495b83, #f7f5f5);
    outline: none;
    transition: opacity 0.2s;
    width: 360px;
    padding: 1px;
    
  
  }

  
  .slider:hover {
    opacity: 1;
  }
  .result_image_containerr{
    margin-top: -10px;
  }
  
  .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  z-index: 2;
  
 
}



  
  .slider-values {
    display: flex;
    justify-content: space-between;
    width: 360px;
   
  
  }
  
  .slider-value {
    position: relative;
    font-size: 16px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;

  }
  
  .slider-value.active {
    opacity: 1;
            }
   
      .image-placeholder-contain {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 6px;
        width: 540px;
        height: 560px;
        border-radius: 10px;
        margin: 0 auto;

      }
      .image-placeholder-containn{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 6px;
        width: 540px;
        height: 560px;
     
   
      }
      
      .image-placehold {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-image: linear-gradient(79deg, #809ad4, #5d74a5 72%);
        border-radius: 10px;
        margin-top: 10px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }
      .result_container {
        justify-content: flex-start;
        align-items: left;
        display: flex;
        flex-wrap: wrap;
        width: 620px;
        height: 480px;
        margin-left: -500px;  
    
        }
  
  
        .loading-img{
          position: absolute; 
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: white;
        
        }
      
        .image_container {
          position: relative;
          width: calc(45% - 45px);
          height: 280px;
          margin-right: 10px;
          margin-bottom: 10px;
         
       
        }
        
        .image_container:last-child {
          margin-right: 20;
        }
        
        .result_image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
       
        
 .autocomplete-prompt{
  margin-top: 120px;
  margin-left: -260px;
  width: 200px;
  font-size: 18px;
  background-color:#e4e6ed;
  color: #222;
  border-width: thin;
  border-radius: 10px;
  outline: none;
 }     

      
          .prom {
       
            width: 700px;
            margin-left: auto;
            margin-right: auto;
            padding: 24px;
            font-size: 18px;
            background-color:white;
            border: solid 1px #d1cfcf;
            color: #222;
            border-radius: 10px;
            outline: none;
            margin-top: -10px;
          
            
          }
          .prom::placeholder {
            color: #5c5c5c;
          }
          .prompt-result {
            width: 380px;
            margin-left: auto;
            margin-right: 10px;
            padding: 18px;
            font-size: 18px;
            background-color:white;
            border: solid 1px #d1cfcf;
            color: #222;
            border-width: thin;
            border-radius: 10px;
            outline: none;
          }
          .style-result {
            width: 200px;
            margin-left: auto;
            margin-right: 10px;
            padding: 18px;
            font-size: 18px;
            background-color:white;
            border: solid 1px #d1cfcf;
            color: #222;
            border-width: thin;
            border-radius: 10px;
            outline: none;
          
            
          }
          .label-input-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
           
          }
          #la {
            display: block;
            text-align: left;
            font-size: 22px;
            margin-top: 10px;
            padding: 20px 0;
            align-self: flex-start;
            font-weight: bold;
          }
             
          
          .upload-img {
            font-size: 22px;
            font-weight: 600;
            color: #809ad4;
          }
          .file-upload{
            color: #809ad4;
           
          }
 
     .art-contain {
      position: relative; 
      display: flex; 
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      width: 700px;

   
      
          }
          .label-art-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 20px;
           
            
          }
     
          #art-st {
            font-size: 22px;
            font-weight: 600;
            color: white;
           
          
        
          }
          
          .art-sty-container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            
          
        
          }
       
          .art-styl {
          
            cursor: pointer;
            border-radius: 8px;
        
          }
          
          .art-styl img {
            width: 90px;
            height: 90px;
            border-radius: 8px;
            border: 2px solid transparent;
          }
          
          .art-styl img.selected {
            border: 3px solid white;
          }
          
          .art-styl p {
            color: white;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            word-wrap: break-word;
          }

        
.advanced-options-button{
  border: none;
  color: #809ad4;
  font-size: 16px;
  font-weight: 600;
  margin-top: 40px;
}
   

.button-generation{ 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin-left: 200px;
    padding: 20px;
    font-size: 20px;
    margin-top: 40px;
    font-weight: 600;
    color: whitesmoke;
    background-color: #809ad4;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;

  }
 
  .button-default {
   
 
   margin-top: -500px;
  }
  
  .button-loading {
  
    
    margin-top: -240px;
 
  }
  
  .button-generated {
   

    margin-top: -240px;
   
  }
  .button-uploaded {

       margin-top: 400px;
  
  }
  .button-generation.active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin-left: 200px;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    color: white;
    background-color: #5d74a5;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;

}

.inputt-file{
 
  width: 700px;
  padding: 24px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border: 2px dashed #d1cfcf;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.result_imgg{
  width: 300px;
  height: 300px;
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;

}
}

  @media (min-width: 1025px) and (max-width: 1800px){
    .cont-home {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 600px;
 
    }
    
  
    .input-placeholder{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

    } 
  
            .label-input-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 30px;
              width: 600px;
          
          }
          

          #la {
            display: block;
            text-align: left;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
            font-weight: bold;
           
          }
              
              .art-contain {
                display: flex;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                width: 600px;
           
              
              }
              .label-art-wrapper {
                flex-direction: column;
                justify-content: center;
                margin-top: 20px;
                
              }
              
              #art-st {
            
                font-size: 18px;
                font-weight: 600;
              
            
              }
              
          
              
              .art-sty-container {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 10px;
           
              }
           
              .art-styl {
                margin: 0 6px;
                cursor: pointer;
                border-radius: 4px;
          
              }
              
              .art-styl img {
                width: 78px;
                height: 78px;
                border-radius: 8px;
                border: 2px solid transparent;
              }
              
              .art-styl img.selected {
                border: 3px solid white;
              }
              
              .art-styl p {
                color: white;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                word-wrap: break-word;
              }
    
              
              .art-styl:hover .style-name {
                opacity: 1; 
              }
    
              .rec-carousel {
                width: 100%;
                
    
              }
              
           
              .rec-arrow {
             
                background-color: white !important;
                color: white;
                border: none;
                border-radius: 50%;
                margin-top: -50px !important;
                padding: 8px; 
                font-size: 20px;
                cursor: pointer;
                transition: background-color 0.3s ease-in-out;
              }
              
              .rec-arrow:hover {
                background-color: #5d74a5 !important; 
              
              }
         
              .rec-dot {
                background-color: #5d74a5 !important;
                box-shadow: 0 0 1px 3px #5d74a5 !important;
                border: 2px solid transparent;
                width: 4px !important;
                height: 4px !important;
                margin: 0 4px;
                margin-top: -20px !important;
                cursor: pointer;
                border-radius: 50%;
                transition: background-color 0.3s ease-in-out;
              }
              
              .rec-dot.rec-dot_active {
                background-color: white !important; 
                border: solid 1px white !important;
               
              }
            
              .rec-dot .selected{
                border: solid 1px white !important;
              }
    
       
  
    .loading-img{
      position: absolute; 
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
    
    }
    .inputt-file{
     
      width: 600px;
      padding: 18px;
      background-color: white;
      margin-left: auto;
      margin-right: auto;
      border: 2px dashed #d1cfcf;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .cancel-button {
      font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(171, 171, 171, 0.3);
  border: none;
  border-radius: 50%;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  position: absolute; /* Add this line */
  top: 10px; 
  right: 10px;
  z-index: 9999;
    }
    

.button-generation{ 
 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 280px;
  padding: 16px;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 600;
  color: whitesmoke;
  background-color: #809ad4;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;

}

.button-default {
 margin-top: -400px;
}

.button-loading {
  margin-top: -240px;
}

.button-generated {
  margin-top: -240px;

}
.button-uploaded {

     margin-top: 180px;

}
.button-generation.active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding: 16px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: #5d74a5;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;

}
.input-button-container {
  display: flex;
  position: relative;
  width: 600px;

}
.prom {
       
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 18px;
  font-size: 18px;
  background-color:white;
  border: solid 1px #d1cfcf;
  color: #222;
  border-radius: 10px;
  outline: none;
  margin-top: -10px;

  
}
.prom::placeholder {
  color: #5c5c5c;
}
 
.random-button{
  position: absolute;
  display: flex;
  background-color: transparent;
  color: #809ad4;
  border: none;
  padding: 8px;
  right: 0;
  margin-top: 5px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.random-button:hover {
  background-color: transparent; 
  color: #5d74a5;
}        

    .selected {
      background-color: white; 
    }
    .alice-carousel__dots {
      position: absolute;
      bottom: 10px; 
      left: 0;
      right: 0;
    }
      

    .upload-img {
      font-size: 22px;
      font-weight: 600;
      color: #809ad4;
    }
    .file-upload{
      color: #809ad4;
     
    }
    
    .hom-placeholders {
      display: flex;
      justify-content: flex-start; 
      align-items: flex-start; 
      margin-left: 100px;
      margin-right: 0;
      margin-top: 20px;
    }
    .images-generation{
      display: flex;
      justify-content: center; 
      align-items: center; 
      max-height: 480px;
      margin-top: -30px;
    
    
    }
    .image-placeholder-contain {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 6px;
      width: 480px;
      height: 540px;
      border-radius: 10px;
      margin-top: 60px;

    }
    .image-placeholder-containn{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 6px;
      width: 480px;
      height: 540px;
     
 
    }
    
    .image-placehold {
      position: relative;
      width: 100%;
      height: 80%;
      object-fit: cover;
      background-image: linear-gradient(79deg, #809ad4, #5d74a5 72%);
      border-radius: 10px;
      margin-top: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    
   
    .icons {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    
    .icons .download-image,
    .icons .edit-icon-image {
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      font-size: 18px;
      cursor: pointer;
    }
    .result_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
     
 
  
  .result_image_container {
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    margin-bottom: 30px;
    width: 400px;
    height: 232px;
    background-color:transparent;
    border-radius: 10px;
  
 


  }

  .result_imaage_container{
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 232px;
    background-color:transparent;
    border-radius: 10px;
    padding: 20px;
   
  }
    
  .result_img{
    
    width: 400px;
    height: 232px;
    border-radius: 10px;
    opacity: 0.5;
  
  }
  
  .resultt_img{
   
    width: 400px;
    height: 232px;
    border-radius: 10px;
  
  }
  
  .label-art-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    
  }
  .result_file{
    width: 420px;
    height: 232px;
    border-radius: 10px;
  }

  .iconss {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 89%;
  
  }
  .iconss .download-img{
    width: 25px;
    height: 25px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-left-radius: 10px;
  }
  .iconss .edit-icon-img {
    width: 25px;
    height: 25px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-right-radius: 10px;
  }
  .slider-values-range {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto; 
  }
  .slider {
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(to left, #495b83, #f7f5f5);
    outline: none;
    transition: opacity 0.2s;
    width: 360px;
    padding: 1px;
    
  
  }

  
  .slider:hover {
    opacity: 1;
  }
  .result_image_containerr{
    margin-top: -10px;
  }
  
  .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  z-index: 2;
  
 
}



  
  .slider-values {
    display: flex;
    justify-content: space-between;
    width: 360px;
   
  
  }
  
  .slider-value {
    position: relative;
    font-size: 16px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
    
  
    
  }
  
  .slider-value.active {
    opacity: 1;
            }
         
          }  

        
     
                  @media (min-width: 480px) and (max-width: 1025px) {
                    .cont-home {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      padding: 10px;
                    }
                    .input-button-container {
                      display: flex;
                      position: relative;
                      width: 500px;
                    
                    }
                    .prom {
                           
                      width: 500px;
                      margin-left: auto;
                      margin-right: auto;
                      padding: 16px;
                      font-size: 16px;
                      background-color:white;
                      border: solid 1px #d1cfcf;
                      color: #222;
                      border-radius: 10px;
                      outline: none;
                      margin-top: -10px;
                    
                      
                    }
                    .prom::placeholder {
                      color: #5c5c5c;
                    }
                     
                    .random-button{
                      position: absolute;
                      display: flex;
                      background-color: transparent;
                      color: #809ad4;
                      border: none;
                      padding: 8px;
                      right: 0;
                      border-radius: 30px;
                      cursor: pointer;
                      font-size: 16px;
                      font-weight: 600;
                    }
                    
                    .random-button:hover {
                      background-color: transparent; 
                      color: #5d74a5;
                    }      
                    .prompt-container {
                      display: block;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      margin-top: 10px;
                      
                    }
                    
                    .label-input-wrapper {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    }
                    
                    .cancel-button {
                      font-size: 16px;
                  font-weight: 600;
                  cursor: pointer;
                  color: white;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: rgba(171, 171, 171, 0.3);
                  border: none;
                  border-radius: 50%;
                  backdrop-filter: blur(7px);
                  -webkit-backdrop-filter: blur(7px);
                  position: absolute; 
                  top: 10px; 
                  right: 10px;
                  z-index: 9999;
                    }
                     
                 
  .alice-carousel__dots {
    position: absolute;
    bottom: 10px; 
    left: 0;
    right: 0;
  }       
            
          .file-upload{
            color: #809ad4;
           
            
          }
          
                    #la {
                      display: block;
                      text-align: left;
                      font-size: 18px;
                      font-weight: 600;
                      margin-bottom: 10px;
                      align-self: flex-start;
                      font-weight: bold;
                    }
                   
                   
        
        .random-button:hover {
          background-color: transparent; 
          color: #5d74a5;
        }
        .alice-carousel__next-btn
       .alice-carousel__next-btn-item
  { 
    display: none !important;
  }

  .alice-carousel__prev-btn
  .alice-carousel__prev-btn-item
{ 
display: none !important;
}
                  
                .art-contain {
                  display: flex;
                  text-align: center;
                  margin-left: auto;
                  margin-right: auto;
                  justify-content: center;
                  width: 500px;
             
                
                }
                .label-art-wrapper {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-top: 10px;
                  
                  
                }
                
                #art-st {
                  
                  font-size: 18px;
                  font-weight: 600;
                  margin-bottom: -10px;
                 
                }
                
            
                
                .art-sty-container {
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  align-items: center;
                  margin-top: 10px;
             
                }
             
                .art-styl {
            
                  cursor: pointer;
                  border-radius: 4px;
            
                }
                
                .art-styl img {
                  width: 70px;
                  height: 70px;
                  border-radius: 8px;
                  border: 2px solid transparent;
                }
                
                .art-styl img.selected {
                  border: 3px solid white;
                }
                
                .art-styl p {
                  color: white;
                  text-align: center;
                  font-size: 15px;
                  font-weight: 500;
                  word-wrap: break-word;
                }
      
                
                .art-styl:hover .style-name {
                  opacity: 1; 
                }
             
                
                        .button-generation{ 
 
                          display: flex;
                          justify-content: center;
                          flex-direction: column;
                          align-items: center;
                          margin-left: auto;
                          margin-right: auto;
                          margin-top: 20px;
                          width: 260px;
                          padding: 14px;
                          font-size: 20px;
                          font-weight: 600;
                          color: whitesmoke;
                          background-color: #809ad4;
                          border-radius: 50px;
                          border: none;
                          outline: none;
                          cursor: pointer;
                        
                        }
                        
                        .button-default {
                         margin-top: -440px;
                        }
                        
                        .button-loading {
                          margin-top: -240px;
                        }
                        
                        .button-generated {
                          margin-top: -240px;
                        
                        }
                        .button-uploaded {
                        
                             margin-top: 180px;
                        
                        }
                        .button-generation.active {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: 260px;
                          padding: 14px;
                          font-size: 20px;
                          font-weight: 600;
                          color: white;
                          background-color: #5d74a5;
                          border-radius: 50px;
                          border: none;
                          outline: none;
                          cursor: pointer;
                        
                        }
                    
                .inputt-file{
                  width:  500px;
                  padding: 16px;
                  margin-left: auto;
                  margin-right: auto;
                  border: 2px dashed grey;
                  background-color: white;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                }
                
                .upload-img {
                  font-size: 18px;
                  font-weight: 600;
                  color: #809ad4;
                }
          
                .hom-placeholders {
                  display: flex;
                  justify-content: center; 
                  align-items: center; 
                  margin-left: 20px;
                  margin-right: 0;
                  margin-top: 100px;
                }
                
                .images-generation{
                  display: flex;
                  justify-content: center; 
                  align-items: center; 
                  max-height: 480px;
                  margin-top: -20px;
                
                }
                .image-placeholder-contain {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  grid-template-rows: repeat(2, 1fr);
                  gap: 6px;
                  width: 440px;
                  height: 540px;
                  border-radius: 10px;
                  margin-top: 60px;
            
                }
                .image-placeholder-containn{
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  grid-template-rows: repeat(2, 1fr);
                  gap: 6px;
                  width: 440px;
                  height: 540px;
                 
             
                }
                
                .image-placehold {
                  position: relative;
                  width: 100%;
                  height: 80%;
                  object-fit: cover;
                  background-image: linear-gradient(79deg, #809ad4, #5d74a5 72%);
                  border-radius: 10px;
                  margin-top: 10px;
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                }
                .icons {
                  display: flex;
                  justify-content: space-between;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                }
                
                .icons .download-image,
                .icons .edit-icon-image {
                  background-color: rgba(0, 0, 0, 0.6);
                  color: white;
                  font-size: 18px;
                  cursor: pointer;
                }
                .result_image {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 10px;
                }
                 
             
                .loading-img{
                  position: absolute; 
                  left: 0;
                  right: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  color: white;
                
                }
              
              .result_image_container {
                position: relative;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: -20px;
                margin-bottom: 20px;
                width: 360px;
                height: 220px;
                background-color:transparent;
                border-radius: 10px;
                padding: 20px;
            
            
              }
       
              .result_imaage_container{
                position: relative;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 360px;
                height: 220px;
                background-color:transparent;
                border-radius: 10px;
                padding: 20px;
              }
               
        .result_img{
          
          width: 360px;
          height: 220px;
          opacity: 0.2;
          border-radius: 10px;
          
      
        }
        
        .resultt_img{
         
          width: 360px;
          height: 220px;
          border-radius: 10px;
         
          
      
        }
        .iconss {
          display: flex;
          justify-content: space-between;
          position: absolute;
          left: 0;
          right: 0;
          top: 89%;
        
        }
        .iconss .download-img{
          width: 25px;
          height: 25px;
          padding: 3px;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          font-size: 18px;
          cursor: pointer;
          border-bottom-left-radius: 10px;
        }
        .iconss .edit-icon-img {
          width: 25px;
          height: 25px;
          padding: 3px;
          background-color: rgba(0, 0, 0, 0.6);
          color: white;
          font-size: 18px;
          cursor: pointer;
          border-bottom-right-radius: 10px;
        }
        .slider-values-range {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          pointer-events: auto; 
        }
        .slider {
          -webkit-appearance: none;
          appearance: none;
          background: linear-gradient(to left, #495b83, #f7f5f5);
          outline: none;
          transition: opacity 0.2s;
          width: 320px;
          padding: 1px;
          
        
        }
      
        
        .slider:hover {
          opacity: 1;
        }
        .result_image_containerr{
          margin-top: -10px;
        }
        
        .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
        z-index: 2;
        
       
      }
      
      
      
        
        .slider-values {
          display: flex;
          justify-content: space-between;
          width: 320px;
         
        
        }
        
        .slider-value {
          position: relative;
          font-size: 16px;
          color: white;
          font-weight: 600;
          margin-bottom: 10px;
          
        
          
        }
        
        .slider-value.active {
          opacity: 1;
        }
              
                  }
        
        
          
        
            @media (max-width: 480px) {
              .cont-home {
                display: flex;
                flex-direction: column;
                align-items: center;
               
              }
              .input-button-container {
                display: flex;
                position: relative;
                width: 362px;
              
              }
              .prom {
                     
                width: 362px;
                margin-left: auto;
                margin-right: auto;
                padding: 14px;
                font-size: 16px;
                background-color:white;
                border: solid 1px #d1cfcf;
                color: #222;
                border-radius: 10px;
                outline: none;
             
              
                
              }
              .prom::placeholder {
                color: #5c5c5c;
              }
               
              .random-button{
                position: absolute;
                display: flex;
                background-color: transparent;
                color: #809ad4;
                border: none;
                padding: 6px;
                margin-top: 10px;
                right: 0;
                border-radius: 30px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 600;
              }
              
              .random-button:hover {
                background-color: transparent; 
              }      
              .prompt-container {
                display: block;
                align-items: center;
                justify-content: center;
                text-align: center;
                
              }
              .cancel-button {
                font-size: 16px;
            font-weight: 600;
            cursor: pointer;
            color: white;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(171, 171, 171, 0.3);
            border: none;
            border-radius: 50%;
            backdrop-filter: blur(7px);
            -webkit-backdrop-filter: blur(7px);
            position: absolute; 
            top: 20px; 
            right: 10px;
            z-index: 9999;
              }
              
              .alice-carousel__next-btn
              .alice-carousel__next-btn-item
         { 
           display: none !important;
         }
       
         .alice-carousel__prev-btn
         .alice-carousel__prev-btn-item
       { 
       display: none !important;
       }
         
              .art-contain {
                display: flex;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                width: 362px;
           
              
              }
              
              .label-input-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: left;
              
                
               
            
              }
              
    .file-upload{
      color: #809ad4;
     
      
    }
    
              #la {
                display: block;
                text-align: left;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10px;
                align-self: flex-start;
                font-weight: bold;
              }
              
  
  .alice-carousel__dots {
    position: absolute;
    bottom: 10px; 
    left: 0;
    right: 0;
  }       
                 
                  .label-art-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                 
                  }
                  
                  #art-st {
                  
                    font-size: 18px;
                    font-weight: 600;
                 
                
                  }
                  
              
                  
                  .art-sty-container {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-top: 10px;
               
                  }
               
                  .art-styl {
                  
                    cursor: pointer;
                    border-radius: 4px;
              
                  }
                  
                  .art-styl img {
                    width: 60px;
                    height: 60px;
                    border-radius: 8px;
                    border: 2px solid transparent;
                  }
                  
                  .art-styl img.selected {
                    border: 3px solid white;
                  }
                  
                  .art-styl p {
                    color: white;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                    word-wrap: break-word;
                  }
        
                  
                  .art-styl:hover .style-name {
                    opacity: 1; 
                  }
               

               
                  .button-generation{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 200px; 
                    padding: 12px;
                    margin-top: 20px;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 18px;
                    font-weight: 600;
                    color: whitesmoke;
                    background-color: #809ad4;
                    border-radius: 50px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                
                  }
                  .button-default {
         
                    margin-top: -320px;
                   }
                   
                   .button-loading {
                   
                     
                     margin-top: -180px;
                  
                   }
                   
                   .button-generated {
            
                     margin-top: -180px;
                    
                   }
                   .button-uploaded {
                 
                        margin-top: 40px;
                   
                   }
                
                  .button-generation.active {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 200px;
                    padding: 12px;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 18px;
                    font-weight: 600;
                    color: white;
                    background-color: #5d74a5;
                    border-radius: 50px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                }
             
          .inputt-file{
          
            width:  362px;
            padding: 14px;
            margin-left: auto;
            margin-right: auto;
            border: 2px dashed grey;
            background-color: white;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
          
          .upload-img {
            font-size: 18px;
            font-weight: 600;
            color: #809ad4;
          }
        .images-generation{
          display: flex;
          justify-content: center;
          align-items: center; 
          margin-top: -10px;
      
         
        }
          .hom-placeholders {
            display: flex;
            justify-content: center; 
            align-items: center; 
            margin-left: 20px;
            margin-right: 0;
            margin-top: 100px;
          }
          
          .image-placeholder-contain {
            display: grid;
            justify-content: center; /* Updated to flex-start */
            align-items: center; 
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 6px;
            width: 340px;
            height: 380px;
       
          
          }
          
       
          .image-placeholder-containn{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 6px;
            width: 340px;
            height: 380px;
            
       
          }
          
          .image-placehold {
            position: relative;
            width: 100%;
            height: 90%;
            object-fit: cover;
            background-image: linear-gradient(79deg, #809ad4, #5d74a5 72%);
            border-radius: 10px;
            margin-top: 10px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          }
          .icons {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
          
          .icons .download-image {
            background-color: rgba(0, 0, 0, 0.6);
            color: white;
            font-size: 18px;
            cursor: pointer;
            border-bottom-left-radius: 10px;
          

          }
          .icons .edit-icon-image{
            background-color: rgba(0, 0, 0, 0.6);
            color: white;
            font-size: 18px;
            cursor: pointer;
            border-bottom-right-radius: 10px;
          }

          .result_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
           
       
          .loading-img{
            position: absolute; 
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
          
          }
        
        .result_image_container {
          position: relative;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 280px;
          height: 240px;
          margin-top: -20px;
          background-color:transparent;
          border-radius: 10px;
          padding: 20px;
      
      
        }
        .resultt_image_container{
          position: relative;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 280px;
          height: 240px;
          margin-top: -70px;
          background-color:transparent;
          border-radius: 10px;
          padding: 20px;
        }
        .result_imaage_container{
          position: relative;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 260px;
          margin-bottom: -20px;
          margin-top: -20px;
          background-color:transparent;
          border-radius: 10px;
          padding: 20px;
        }
         
  .result_img{
    
    width: 280px;
    height: 220px;
    opacity: 0.2;
    border-radius: 10px;
    

  }
  
  .resultt_img{
   
    width: 280px;
    height: 220px;
    border-radius: 10px;
   
    

  }
  .iconss {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 84%;
    margin-left: 10px;
    margin-right: 10px;
  
  }
  .iconss .download-img{
    width: 23px;
    height: 23px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-left-radius: 10px;
  }
  .iconss .edit-icon-img {
    width: 23px;
    height: 23px;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-bottom-right-radius: 10px;
  }
  
  .slider-values-range {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto; 
  }
  .slider {
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(to left, #495b83, #f7f5f5);
    outline: none;
    transition: opacity 0.2s;
    width: 260px;
    padding: 1px;
    
  
  }

  
  .slider:hover {
    opacity: 1;
  }
  .result_image_containerr{
    margin-top: -10px;
  }
  
  .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  z-index: 2;
  
 
}



  
  .slider-values {
    display: flex;
    justify-content: space-between;
    width: 260px;
   
  
  }
  
  .slider-value {
    position: relative;
    font-size: 16px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
    
  
    
  }
  
  .slider-value.active {
    opacity: 1;
  }
        
        
              }
              
