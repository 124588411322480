@media (min-width: 1800px) {
.userprofile{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .user-name{
    font-size: 32px;
  
  }
  .prof-user {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center; 
    margin-top: -40px;
  }
  .arrow-back-button {
  
    position: absolute; 
    left: 20px;
    top: 60px;
    padding: 20px;
    font-size: 26px;
    font-weight: 600;
    color: whitesmoke;
    background-color: transparent;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .arrow-back-icon{
    width: 60px !important;
    font-size: 32px !important;
  }
 .mdb-icon{
  margin-top: 140px !important;
  font-size: 18px !important;
    
 }
 

  .cover {
    position: relative;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin-bottom: 20px; 
  }
  .pic-size{
    width: 120px !important;
    height: 120px !important;
    border: solid 3px white;
  }
  #lbel {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px;
    color: white;
  }
  
  .title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
   
  }

  .profile-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .save-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    padding: 20px;
    margin-top: 60px;
    font-size: 20px;
    font-weight: 600;
    color: whitesmoke;
    background-color: #809ad4;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .user-information{
      
    display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -60px;
  
  }
  .user-input{
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
  }
  .coverPicContainer{
    margin-left: -200px;
  }
  #inpt{
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    font-size: 18px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  
  #it{
      width: 100%;
      padding: 30px 20px;
      margin: 8px 0;
      border-radius: 12px;
      background-color: #545454;
      display: inline-block;
      box-sizing: border-box;
  }
  
  #int{
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    font-size: 18px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  input::placeholder {
      color: white;
  }
  
  
  #head{
      text-align: left;
      margin-top: 40px;
      margin-left: 12px;
      color: #787878;
      font-size: 21px;
      
  }
  #ico{
      background-color: transparent;
      border: none;
      color: white;
      margin-top: 150px;
      margin-left: 850px;
      text-align: center;
      justify-content: center;
  }
  
  #icon{
      background-color: transparent;
      border: none;
      color: white;
      text-align: center;
      justify-content: center;
     
  }
  
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: -20px;
    width: 30%;
    height: 30%;
    color: white;
    background-color: transparent;
  }

}
@media (min-width: 1025px) and (max-width: 1800px){
  .userprofile{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -60px;
  }

  .user-name{
    font-size: 30px;
  
  }
  .prof-user {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center; 
    margin-top: -60px;
  }
  .arrow-back-button {
  
    position: absolute; 
    left: 20px;
    top: 60px;
    padding: 10px;
    font-size: 24px;
    font-weight: 600;
    color: whitesmoke;
    background-color: transparent;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .arrow-back-icon{
    width: 50px !important;
    font-size: 30px !important;
  }
 .mdb-icon{
  margin-top: 140px !important;
  font-size: 18px !important;
    
 }
 

  .cover {
    position: relative;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin-top: -40px;
 
  }
  .pic-size{
    width: 100px !important;
    height: 100px !important;
    border: solid 3px white;
  }
  #lbel {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px;
    color: white;
  }
  
  .title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
   
  }

  .profile-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .save-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    padding: 18px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    color: whitesmoke;
    background-color: #809ad4;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .user-information{
      
    display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -60px;
  
  }
  .user-input{
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
  }
  .coverPicContainer{
    margin-left: -200px;
  }
  #inpt{
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 18px;
    font-size: 16px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  
  #it{
      width: 100%;
      padding: 30px 20px;
      margin: 8px 0;
      border-radius: 12px;
      background-color: #545454;
      display: inline-block;
      box-sizing: border-box;
  }
  
  #int{
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    font-size: 18px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  input::placeholder {
      color: white;
  }
  
  
  #head{
      text-align: left;
      margin-top: 40px;
      margin-left: 12px;
      color: #787878;
      font-size: 21px;
      
  }
  #ico{
      background-color: transparent;
      border: none;
      color: white;
      margin-top: 150px;
      margin-left: 850px;
      text-align: center;
      justify-content: center;
  }
  
  #icon{
      background-color: transparent;
      border: none;
      color: white;
      text-align: center;
      justify-content: center;
     
  }
  
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: -20px;
    width: 25%;
    height: 25%;
    color: white;
    background-color: transparent;
  }
}
@media (min-width: 480px)  and (max-width: 1025px){
  .userprofile{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -60px;
  }
  .modalBackgroundd {
    text-align: center;
    display:flex;
    margin-top: -150px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
  }
  .user-name{
    font-size: 24px;
  
  }
  .prof-user {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center; 
    margin-top: -60px;
  }
  .arrow-back-button {
  
    position: absolute; 
    left: 10px;
    top: 60px;
    padding: 16px;
    font-size: 18px;
    font-weight: 600;
    color: whitesmoke;
    background-color: transparent;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .arrow-back-icon{
    width: 40px !important;
    
  }
  .mdb-icon{
    margin-top: 120px !important;
    font-size: 18px !important;
      
   }
   
  .pic-size{
    width: 90px !important;
    height: 90px !important;
    border: solid 3px white;
  }
  .cover {
    position: relative;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin-top: -30px;
  }
  
  #lbel {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
    color: white;
  }
  
  .title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
   
  }

  .profile-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .save-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    padding: 18px;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 600;
    color: whitesmoke;
    background-color: #809ad4;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .user-information{
      
    display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -60px;
  
  }
  .user-input{
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
  }
  .coverPicContainer{
    margin-left: -200px;
  }
  #inpt{
    width: 460px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    font-size: 16px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  
  #it{
      width: 100%;
      padding: 30px 20px;
      margin: 8px 0;
      border-radius: 12px;
      background-color: #545454;
      display: inline-block;
      box-sizing: border-box;
  }
  
  #int{
    width: 460px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    font-size: 18px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  input::placeholder {
      color: white;
  }
  
  
  #head{
      text-align: left;
      margin-top: 40px;
      margin-left: 12px;
      color: #787878;
      font-size: 21px;
      
  }
  #ico{
      background-color: transparent;
      border: none;
      color: white;
      margin-top: 150px;
      margin-left: 850px;
      text-align: center;
      justify-content: center;
  }
  
  #icon{
      background-color: transparent;
      border: none;
      color: white;
      text-align: center;
      justify-content: center;
     
  }
  
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: -20px;
    width: 20%;
    height: 20%;
    color: white;
    background-color: transparent;
  }
}

@media (max-width: 480px) {
  .userprofile{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -40px;
  }
  .modalBackgroundd {
    text-align: center;
    display:flex;
    margin-top: -150px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
  }
  .user-name{
    font-size: 24px;
  
  }
  .prof-user {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center; 
    margin-top: -60px;
  }
  .arrow-back-button {
  
    position: absolute; 
    left: 20px;
    top: 60px;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    color: whitesmoke;
    background-color: transparent;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .arrow-back-icon{
    width: 20px !important;
    
  }
  .mdb-icon{
    margin-top: 120px !important;
    font-size: 16px !important;
      
   }
   
  .pic-size{
    width: 80px !important;
    height: 80px !important;
    border: solid 3px white;
  }
  .cover {
    position: relative;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin-top: -30px;
  }
  
  #lbel {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
    color: white;
  }
  
  .title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
   
  }

  .profile-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .save-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    padding: 16px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    color: whitesmoke;
    background-color: #809ad4;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .user-information{
      
    display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -60px;
  
  }
  .user-input{
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
  }
  .coverPicContainer{
    margin-left: -200px;
  }
  #inpt{
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    font-size: 16px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  
  #it{
      width: 100%;
      padding: 30px 20px;
      margin: 8px 0;
      border-radius: 12px;
      background-color: #545454;
      display: inline-block;
      box-sizing: border-box;
  }
  
  #int{
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 22px;
    font-size: 16px;
    background-color:white;
    border: solid 1px #d1cfcf;
    color: #222;
    border-radius: 10px;
    outline: none;
  }
  input::placeholder {
      color: white;
  }
  
  
  #head{
      text-align: left;
      margin-top: 40px;
      margin-left: 12px;
      color: #787878;
      font-size: 21px;
      
  }
  #ico{
      background-color: transparent;
      border: none;
      color: white;
      margin-top: 150px;
      margin-left: 850px;
      text-align: center;
      justify-content: center;
  }
  
  #icon{
      background-color: transparent;
      border: none;
      color: white;
      text-align: center;
      justify-content: center;
     
  }
  
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: -20px;
    width: 20%;
    height: 20%;
    color: white;
    background-color: transparent;
  }

}
