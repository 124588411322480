.page {
  position: relative;
  background-color: rgb(3, 15, 29);
  margin: 0; 
  overflow: hidden; 
 
}
.page-one {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; /* Cover the full height */
  margin: 0;
}
.img-styles {
  /* Your existing styles */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin: 5;
 
}

.img-styles img {
  width: 360px;
  height: 380px;
  margin: 5px;
  filter: blur(1px);
}

.content {
  position: absolute;
  top: -27%; /* Adjust the value to position the content higher or lower */
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  z-index: 1;
}

.create {
  font-size: 52px;
  font-weight: bold;
  margin-bottom: 10px;
}

  #boutt {
    border-radius: 50px;
    background-color: #809ad4;
    padding: 16px 50px;
    margin-top: 30px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
  
  }
.img1{
  opacity: 0.6;
}
.img2 {
 opacity: 0.3; /* Adjust the value for the desired blur effect */
}
.img3 {
  opacity: 0.08; /* Adjust the value for the desired blur effect */
}

  @keyframes scrollAnimation {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  
  .scroll {
    position: absolute;
    top: calc(50% + 400px); 
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-weight: 600;
    font-size: 26px;
  }
  
  .scroll p {
    animation: scrollAnimation 2s infinite;
  }
  @media (min-width: 1025px) and (max-width: 1800px){
    .img-styles img {
      width: 240px;
      height: 240px;
      margin: 5px;
      filter: blur(1px);
    }
    .create {
      font-size: 42px;
      font-weight: bold;
    
    }
    
      #boutt {
        border-radius: 50px;
        background-color: #809ad4;
        padding: 14px 40px;
        margin-top: 10px;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        
      
      }
  }
  @media (min-width: 480px) and (max-width: 1025px) {
    .img-styles img {
      width: 300px;
      height: 300px;
      margin: 6px;
      filter: blur(1px);
    }
  }
  @media only screen and (max-width: 480px) {
    .img-styles {
      /* Your existing styles */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      margin: 5;
     
    }
    
    .img-styles img {
      width: 110px;
      height: 110px;
      margin: 5px;
      filter: blur(1px);
    }
    .create {
      font-size: 22px;
      font-weight: bold;
    
    }
    
      #boutt {
        border-radius: 50px;
        background-color: #809ad4;
        padding: 12px 30px;
        margin-top: 10px;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        
      
      }
  }
