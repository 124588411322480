.App {
	text-align: center;
	display: auto;
	min-height: 100vh;
	color:whitesmoke;
	background-color: rgb(8, 18, 32);
	overflow: hidden;
	
  }


.Login {
  text-align: center;
  display:flex;
  width: 420px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(79deg, #7439db, #708cdf 48%, #C66FBC );
  
}

#label {
	font-size: 20px;
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 18px;
  color: rgb(255, 255, 255, 0.8);

}
#in {
	outline: none;
	background-color: white; /* Set the background color with opacity */
	color: #5c5c5c;
	font-size: 18px;
    font-weight: 500;
	border-radius: 10px;
	border: solid 1px #b0b0b0;
	
}
#in::placeholder {
	color: #5c5c5c!important;
  }

 #in:hover{
	border: 1px solid #cfcfcf;
 }
 


 #in::placeholder{
    color: rgba(218, 218, 218, 0.7);
 }
.auth-form-container {
  padding: 5rem;

}

label {
  text-align: left;
  padding: 0.25rem 0;
}

#inp {
  border: none;
  border-radius: 25px;
  width: 100%;
  padding: 25px 20px;
  margin: 10px 0;
  display: inline-block;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #333;
  
}




.gradient-custom-2 {
	/* fallback for old browsers */
	background: #fbc2eb;
	
	/* Chrome 10-25, Safari 5.1-6 */
	background: -webkit-linear-gradient(to right, rgba(251, 194, 235, 1), rgba(166, 193, 238, 1));
	
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background: linear-gradient(to right, rgba(251, 194, 235, 1), rgba(166, 193, 238, 1))
	}
.create {
	font-family: serif;
	font-weight: lighter;
	font-size: 60px;
	margin-top: -80px;
	text-align: center;
	justify-content: center;
		

}


#bout {
	border-radius: 20px;
	background: linear-gradient(79deg, #7439db, #708cdf 48%, #C66FBC );
	padding: 10px 30px;
	margin-left: 1200px;
	float: right;
	color: white;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
}
input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 20px;
 
}


.butn1 {
  border: none;
  background-color: #809ad4; /* Set the background color with opacity */
  color: white;
  padding: 15px;
  border-radius: 10px;
  margin-top:30px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.butn1:hover{
	background-color: #5d74a5;
}



.link-btn {
  background: none;
  border: none;
  color: #484848;
  margin-top:20px;
  text-decoration: underline;
  font-weight: bold ;

}

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}


.notification {

	
	color: #721c24;
	align-self: flex-start;
	font-size: 17px;


  }

.columns-is-vcentered {
	background-color: #222;
	text-align: center;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	
  }
  .column {
	text-align: center;
	
	
  }
  
  .field{
	text-align: left;
	padding: 1rem;
	padding-top: 1px;
  }
  
  
  
  .textarea {
	width: 100%;
	padding: 25px 20px;
	margin: 10px 0;
	display: inline-block;
	border-radius: 10px;
	box-sizing: border-box;
	
  }
  
  .box {
	width:650px;
	padding-top: 1px;
	
	
  }
  
  .label-text {
	text-align: left;
	padding: 4rem;
	padding-top: 0%;
	
  
	
  }
  
.but {
	align-items: center;
	background-color: 	#505050;
	border-radius: 30px;
	border-style: none;
	box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
	box-sizing: border-box;
	color: #222;
	cursor: pointer;
	fill: currentcolor;
	font-family: "Google Sans",Roboto,Arial,sans-serif;
	font-size: 16px;
	font-weight: 600;
	height: 60px;
	justify-content: center;
	letter-spacing: .25px;
	margin-top:50px;
	line-height: normal;
	max-width: 100%;
	overflow: visible;
	padding: 2px 30px;
	position: relative;
	text-align: center;
	text-transform: none;
	transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	width: auto;
	will-change: transform,opacity;
	z-index: 0;
  }
  .dark {
	background-color: #333;
	color: #fff;
	}
.light {
	background-color: #fff;
	color: #333;
	}
.img {
		width: 160%;
		position: absolute;
		left: -150px;
		top: -50px;
	}
.google_btn {
	width: 230px;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 0;
    color: #2c444e;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.google_btn > img{
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.google_btn > span{
    margin-left: 10px;
}


.toast-message {
	background: #222;
	color: white;
}


#lb {
	color: #d60e40;
	font-weight: 500;
	size: 28px;
	
}
.page-content{
	background-color: white;
}
video {
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
  
  .content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	margin-top: 200px;
	flex-direction: column;
	align-items: center;
	color: white;
  }
 
  
